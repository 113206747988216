import Plan from "../../plan"
import { useParams } from "react-router-dom"
import { connect } from "react-redux"

const PlanPage = ({plans}) => {

  const {id} = useParams()
  const schedule = plans.find(item => item.id.toString() === id)

  if (!schedule) {
    return <div className="app-content plan-page">Plan not found</div>
  }

  return (
    <div className="app-content plan-page">
      <a href="/" className="back">&larr; back</a>
      <Plan schedule={schedule}/>
    </div>
  )
}

const mapStateToProps = ({plans}) => {
  return {plans}
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanPage)