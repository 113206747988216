import { Component } from "react"
import Header from "../header"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import ListPage from "../pages/list-page"
import PlanPage from "../pages/plan-page"

import "./app.css"
import { loadState } from "../../actions"
import { connect } from "react-redux"
import { SOUND_URL } from "../../utils"


class App extends Component {

  componentDidMount () {
    this.props.loadState()
    this.playSilentSound()
  }

  /**
   * Воспроизводит звук без громкости ля того чтобы при запуске таймера 
   * и переходе в другую вкладку звук в любом случае проигрался
   */
  playSilentSound = () => {
    const playSound = () => {
      const audio = new Audio(SOUND_URL)
      audio.volume = 0.0
      audio.play()
      document.removeEventListener('click', playSound)
    }
    // нужен слушатель на клик, так браузер не воспроизведёт звук без действия пользователя
    document.addEventListener('click', playSound)
  }

  render () {
    return (
      <div>
        <BrowserRouter>
            <Routes>
              <Route path="/" element={<ListPage/>} />
              <Route path="/plan/:id" element={<PlanPage/>} />
            </Routes>
        </BrowserRouter>
      </div>
    )  
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    loadState: () => dispatch(loadState())
  }
}


export default connect((state)=>state, mapDispatchToProps)(App)