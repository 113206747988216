import { Component } from "react"
import { secondsToMinutesStr, SOUND_URL } from "../../utils"
import "./timer.css"

const INITIAL_TIME = 30*60

class Timer extends Component {

  TIMER_IID = 0
  worker = null

  state = {
    time: INITIAL_TIME,
    process: false,
    pause: false
  }

  startTimer = () => {
    const {pause, time} = this.state

    if (!this.worker) {
      this.worker = new Worker('/worker.js') 
    }

    if (pause) {
      this.setState({pause: false})
      this.worker.postMessage({message: 'start', time})
    } else {
      this.setState({time: INITIAL_TIME })
      this.worker.postMessage({message: 'start', time: INITIAL_TIME})
    } 

    this.props.onStartTimer()
    
    this.worker.onmessage = (e) => {
      if (e.data.message === 'tick') {
        const { time } = e.data
        let process = this.state.process
        if (time <= 0) {
          process = false
          this.props.onDoneTimer()
          this.worker.terminate()
          this.playAudio()
        }
        this.setState({
          time,
          process
        })
      }
    }
  }

  pauseTimer = () => {
    this.worker.postMessage({message: 'pause'})
    this.setState({pause: true})
  }

  stopTimer = () => {
    this.props.onStopTimer()
    this.worker.postMessage({message: 'stop'})
    this.worker.terminate()
    this.setState({
      time: INITIAL_TIME,
      process: false
    })
  }

  playAudio = () => {
    const audio = new Audio(SOUND_URL)
    audio.play()
    audio.remove()
  }

  onChange = () => {
    const process = !this.state.process

    this.setState({process}, () => {
      if (process) {
        this.startTimer()
      } else {
        this.pauseTimer()
      }
    })
  }

  render () {
    const {process, time} = this.state
    const {className} = this.props
    return (
      <div className={`timer ${className}`}>
        <div className="timer__time">{secondsToMinutesStr(time)}</div> 
        <div className={`timer__control timer__control_${process?'pause':'start'}`} onClick={this.onChange}/>
      </div>
    )
  }
}

export default Timer