import { connect } from "react-redux";
import { getDateStr } from "../../utils";
import "./plans-list.css"

const preparePlans = (items) => {
  const formated = {
    years: []
  }
  const plans = [...items].sort((a,b)=>b.createdAt-a.createdAt)
  const yearMap = new Map()
  const monthMap = new Map()

  plans.forEach(item => {
    item.title = getDateStr(item.createdAt)

    const d = new Date(item.createdAt)
    const year = +d.getFullYear()
    const month = d.getMonth() + 1

    if (yearMap.get(year) === undefined) {
      yearMap.set(year, formated.years.length)
      formated.years[yearMap.get(year)] = {
        title: year, 
        month: []
      }
    }

    if (monthMap.get(year+'_'+month) === undefined) {
      monthMap.set(year+'_'+month, formated.years[yearMap.get(year)].month.length)
      formated.years[yearMap.get(year)].month[monthMap.get(year+'_'+month)] = {
        title: month,
        items: []
      }
    }

    const yearIdx = yearMap.get(year)
    const monthIdx = monthMap.get(year+'_'+month)

    formated.years[yearIdx].month[monthIdx].items.push(item)
  })

  return formated
}

const drawItem = (item) => {
  return (
    <div className="plans-list__item" key={item.id}><a href={`/plan/${item.id}`}>{item.title}</a></div>
  )
}

const drawMonth = (month, lang='ru') => {
  const locale = {
    'ru': {
      'month': {
        '1': 'Январь', 
        '2': 'Февраль', 
        '3': 'Март', 
        '4': 'Апрель', 
        '5': 'Май', 
        '6': 'Июнь', 
        '7': 'Июль', 
        '8': 'Август', 
        '9': 'Сентябрь', 
        '10': 'Октябрь', 
        '11': 'Ноябрь', 
        '12': 'Декабрь' 
      }
    }
  }

  const title = locale[lang].month[month.title]

  return (
    <div className="plans-list__month" key={month.title}>
      <h3>{title}</h3>
      <nav className="plans-list__plans">
        {month.items.map(item => drawItem(item))}
      </nav>
    </div>
  )
}

const drawYear = (year) => {
  return (
    <section key={year.title}>
      <h2>{year.title}</h2>
      {year.month.map(month => drawMonth(month))}
    </section>
  )
}

const PlansList = (props) => {
  return (
    <div className="plans-list">
      {props.plans.years.map(year => drawYear(year))}
    </div>
  )
}

const mapStateToProps = ({plans}) => {
  return {
    plans: preparePlans(plans)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlansList)