import { LOAD_STATE, ADD_PLAN, UPDATE_PLAN } from "./actions/types"

const initialState = {
  plans: [],
  tomatoAssoc: ['Nimbus']
}

const reducer = (state=initialState, action) => {
  switch (action.type) {
    case LOAD_STATE: {
      return {
        ...action.payload,
        tomatoAssoc: initialState.tomatoAssoc
      }
    }

    case ADD_PLAN: {
      const {payload} = action
      const index = state.plans.findIndex(item => item.id.toString() === payload.id)
      if (index !== -1) {
        console.info(payload.id + ' already exists')
        return state
      }

      const newState = {
        ...state,
        plans: [
          ...state.plans,
          action.payload
        ]
      }
      return newState
    }

    case UPDATE_PLAN: {
      const { payload } = action
      const { plans } = state
      
      const index = state.plans.findIndex(item => item.id.toString() === payload.id)
      if (index === -1) return state

      const newState = {
        ...state,
        plans: [
          ...plans.slice(0, index),
          payload,
          ...plans.slice(index+1)
        ]
      }
      return newState
    }

    default: {
      return state
    }
  }
}

export default reducer