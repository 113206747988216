export const SOUND_URL = '/cell_phone.mp3'

/**
 * Переводит строку в виде 09:00 в секунды
 * @param {String} str 
 * @returns Секунды
 */
export const strToSeconds = (str) => {
  const [hs, ms] = str.split(":")
  const h = parseInt(hs)
  const m = parseInt(ms)
  const seconds = h * 60 * 60 + m * 60
  return seconds
}

/**
 * Форматирует число в строку с первым нулём
 * @param {Number} n 
 * @returns Строку
 */
export const z = (n) => {
  return n < 10 ? '0' + n : n
}

/**
 * Приводит количество часов к часам в сутках
 * @param {Number} h
 * @returns Число
 */
export const formatH = (h) => {
  return h - Math.floor(h/24)*24

}

/**
 * Переводит секунды к виду 09:00
 * @param {Number} seconds 
 * @returns Строку
 */
export const secondsToStr = (seconds) => {
  const h = Math.floor(seconds / (60*60))
  const m = seconds/60 - h*60
  return z(formatH(h)) + ':' + z(m)
}

export const secondsToMinutesStr = (seconds) => {
  const m = Math.floor(seconds / (60))
  const s = seconds - m*60
  return z(m) + ':' + z(s)
}

export const calculateSchedule = (schedule) => {
  const result = []
  let time = 0

  schedule.forEach((item, idx) => {
    if (item.startTime) {
      // если время начало указано, то просто устанавливаем это время
      const seconds = strToSeconds(item.startTime)
      if (seconds >= time) {
        time = seconds 
      } else {
        time += item.timer
      }
    } else {
      // если не указано, то расчитываем время от предыдущего
      time += item.timer
    }
    result.push({
      ...item,
      startTime: secondsToStr(time)
    })
  })
  return result
}

/**
 * Генерирует 24-х часовой план
 * @returns 
 */
export const getDefaultSchedule = () => {
  const tomato = 1800
  const schedule = []

  for (let i=0; i<24; i++) {
    schedule.push({
      id: i,
      title: "",
      timer: tomato
    })
  }

  schedule[0].startTime = "00:00"

  return schedule
}

export const getDateStr = (timestamp, lang='ru') => {
  const locale = {
    'ru': {
      'day': {
        '0': 'Воскресенье',
        '1': 'Понедельник',
        '2': 'Вторник',
        '3': 'Среда',
        '4': 'Четверг',
        '5': 'Пятница',
        '6': 'Суббота',
      }
    }
  }

  const date = new Date(timestamp)
  const dd = z(date.getDate())
  const mm = z(date.getMonth()+1)
  const YYYY = date.getFullYear()
  const day = locale[lang]['day'][date.getDay()]

  return `${dd}.${mm}.${YYYY}, ${day}`
}