import { ADD_PLAN, LOAD_STATE, UPDATE_PLAN } from "./types";

const STORAGE_KEY = 'tomato-plan'

export const loadState = () => {
  return (dispatch) => {
    const state = window.localStorage.getItem(STORAGE_KEY)
    if (state) {
      dispatch({
        type: LOAD_STATE,
        payload: JSON.parse(state)
      })
    }
  }
}

let debounceTid = 0
export const saveState = (state, timeout=500) => {
  clearTimeout(debounceTid)
  debounceTid = setTimeout(() => {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
  }, timeout)
}

export const addPlan = (payload) => {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_PLAN,
      payload
    })
    saveState(getState(), 0)
    window.open(`/plan/${payload.id}`, '_self')
  }
}

export const updatePlan = (payload) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_PLAN,
      payload
    })
    saveState(getState())
  }
}