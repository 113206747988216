import { Component, Fragment } from "react"
import Timer from "../timer"
import { calculateSchedule, getDateStr, getDefaultSchedule, z } from "../../utils"
import { connect } from "react-redux"
import { updatePlan } from "../../actions"
import { DndContext } from '@dnd-kit/core'

import "./plan.css"

const ARROW_DOWN = 'ArrowDown'
const ARROW_UP = 'ArrowUp'

class Plan extends Component {

  TID = 0
  timerMap = new Map()

  constructor (props) {
    super(props)
    this.state = {
      schedule: this.props.schedule.plan || getDefaultSchedule(),
      clientX: 0,
      clientY: 0,
      offsetY: 0,
      drag: false,
      dragIndex: null,
      dropUnder: null
    }
    console.log(props);
  }

  componentDidMount () {
    document.addEventListener('mousemove', this.calculateDragUnder)
    document.addEventListener('mouseup', () => {
      if (this.state.drag) {
        const {dropUnder, dragIndex} = this.state
        let items = this.state.schedule
        const item = items[dragIndex]

        if (dropUnder !== null) {
          if (dragIndex < dropUnder) {
            items = [
              ...items.slice(0, dragIndex),
              ...items.slice(dragIndex+1, dropUnder),
              ...items.slice(dropUnder, dropUnder+1),
              {...item, drag: false},
              ...items.slice(dropUnder+1)
            ] 
          } else {
            items = [
              ...items.slice(0, dropUnder),
              ...items.slice(dropUnder, dropUnder+1),
              {...item, drag: false},
              ...items.slice(dropUnder+1, dragIndex),
              ...items.slice(dragIndex+1)
            ]
          }
        }
        this.setState({
          drag: false, 
          dropUnder: null, 
          dragIndex: null, 
          schedule: items
        })
        this.savePlan(items)
      }
    })
  }

  calculateDragUnder = (e) => {
    if (this.state.drag) {
      this.state.schedule.forEach((item, idx) => {
        if (item.drag) return 
        const el = document.getElementById('tr_'+item.id)
        const { top, height } = el.getBoundingClientRect()
        const y = this.state.clientY - this.state.offsetY
        if (y >= top && y <= top + height) {
          this.setState({dropUnder: idx})
        }
      })
      this.setState({
        clientX: e.clientX,
        clientY: e.clientY
      })
    }
  }

  changeSchedule = (id, options={}, callback=()=>{}, save=true) => {
    const index = this.state.schedule.findIndex(item => item.id === id)

    const newItems = [
      ...this.state.schedule.slice(0, index),
      {
        ...this.state.schedule[index],
        ...options
      },
      ...this.state.schedule.slice(index+1)
    ]

    this.setState({schedule: newItems}, callback)
    if (save) {
      this.savePlan(newItems)
    }
  }

  savePlan = (items) => {
    this.props.updatePlan({
      ...this.props.schedule,
      plan: items.map(({process, ...values}) => {
        return {
          ...values
        }
      })
    })
  }

  updateCaretPosition = (el, endOffset=0) => () => {
    if (!el.innerText.length) return

    el.focus()
    const textNode = el.firstChild
    const range = document.createRange()
    range.setStart(textNode, endOffset)
    range.setEnd(textNode, endOffset)
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
  }

  onStartTimeInput = (id) => (event) => {
    const time = event.target.innerText
    if (time.match(/^[0-9]{2}:[0-9]{2}$/)) {
      const {endOffset} = window.getSelection().getRangeAt(0)
      this.changeSchedule(id, {startTime: time}, this.updateCaretPosition(event.target, endOffset))
    }
  }

  setTimerRef = (id) => (ref) => {
    this.timerMap.set(id, ref)
  }

  onDone = (id) => (event) => {
    const done = event.target.checked
    if (done) {
      const timer = this.timerMap.get(id)
      timer.worker && timer.pauseTimer()
    } 
    this.changeSchedule(id, {done})
  }

  onChangeTomato = (id) => (event) => {
    this.changeSchedule(id, {isTomato: event.target.checked})
  }

  onInputTomato = (id) => (event) => {
    const {endOffset} = window.getSelection().getRangeAt(0)
    this.changeSchedule(id, {tomato: event.target.innerText},  this.updateCaretPosition(event.target, endOffset))
  }

  onInputTitle = (id) => (event) => {
    const {endOffset} = window.getSelection().getRangeAt(0)
    const title = event.target.innerText
    const {tomatoAssoc=[]} = this.props
    this.changeSchedule(id, {title}, () => {
      this.updateCaretPosition(event.target, endOffset)()
      if (tomatoAssoc.includes(title)) {
        this.changeSchedule(id, { isTomato: true })
      }
    })
  }

  onTimerChange = (id) => (process) => {
    //this.changeSchedule(id, {process}, ()=>{}, false)
  }

  onStartTimer = (id) => () => {
    const d = new Date()
    const startTime = z(d.getHours()) + ':' + z(d.getMinutes())
    this.changeSchedule(id, {startTime}, () => { 
      console.log('on change', startTime)
      this.changeSchedule(id, {process: true}, ()=>{}, false)
    }, true)
  }

  onStopTimer = (id) => () => {
    this.changeSchedule(id, {process: false}, ()=>{}, false)
  }

  onDoneTimer = (id) => () => {
    this.changeSchedule(id, {done: true}, () => {
      this.onStopTimer(id)()
    })
  }

  onStartDrag = (id) => (e) => {
    const el = document.getElementById('tr_'+id)
    const rect = el.getBoundingClientRect()
    const offsetY = e.clientY - rect.top
    const dragIndex = this.state.schedule.findIndex(item => item.id === id)

    this.setState({
      drag: true, 
      dragIndex, 
      offsetY
    }, () => {
      this.changeSchedule(id, {
        drag: true
      }, () => {
        setTimeout(() => {
          this.calculateDragUnder(e)
        }, 1)        
      }, false)
    })
  }

  focusOnItem = (className, classList, idx) => {
    if (classList.contains(className)) {
      const id = idx >=0 && idx < this.state.schedule.length && this.state.schedule[idx].id
      const next = document.getElementById('tr_' + id)
      next && next.querySelector('.'+className).focus()
    }
  }

  onTdKeyDown = (idx) => (e) => {
    if (e.code === ARROW_DOWN) {
      this.focusOnItem('plan__title', e.target.classList, idx+1)
      this.focusOnItem('plan__start-time', e.target.classList, idx+1)
    }
    if (e.code === ARROW_UP) {
      this.focusOnItem('plan__title', e.target.classList, idx-1)
      this.focusOnItem('plan__start-time', e.target.classList, idx-1)
    }
  }

  getRow = (row, idx) => {
    const {id, done, tomato, tomatoIndex, startTime, title, process, drag} = row
    const rowClassName = []
    let style = {}
    if (done) {
      rowClassName.push('plan__row_done')
    }
    if (process) {
      rowClassName.push('plan__row_process')
    }
    if (drag) {
      rowClassName.push('plan__row_drag')
      const top = (this.state.clientY - this.state.offsetY) + document.scrollingElement.scrollTop
      style = {
        top: top + 'px'
      }
    }
    
    const isDropUnder = this.state.dropUnder === this.state.schedule.findIndex(item => item.id === id)

    return (
      <Fragment key={id}>
        <tr className={rowClassName.join(' ')} style={style} id={'tr_'+id}>
          <td className="plan__num">
            <label className="plan__done">
              <input type="checkbox" checked={row.done||false} onChange={this.onDone(id)}/>
            </label>
            <label className="plan__tomato">
              <input type="checkbox" checked={row.isTomato||false} onChange={this.onChangeTomato(id)}/><b/>
            </label>
            <div 
              className="plan__tomato-index"
              contentEditable={true}
              suppressContentEditableWarning={true}
              onInput={this.onInputTomato(id)}
            >{tomatoIndex || tomato}</div>
          </td>
          <td 
            className="plan__start-time" 
            contentEditable={true} 
            suppressContentEditableWarning={true}
            onInput={this.onStartTimeInput(id)}
            onKeyDown={this.onTdKeyDown(idx)}
          >{startTime}</td>
          <td 
            className="plan__title" 
            contentEditable={true} 
            suppressContentEditableWarning={true}
            onInput={this.onInputTitle(id)}
            onKeyDown={this.onTdKeyDown(idx)}
          >{title}</td>
          <td className="plan__timer">
            <Timer 
              className="plan__timer-control"
              onProcessChange={this.onTimerChange(id)}
              onStartTimer={this.onStartTimer(id)}
              onStopTimer={this.onStopTimer(id)}
              onDoneTimer={this.onDoneTimer(id)}
              ref={this.setTimerRef(id)}
            />
            <div className="plan__dragger" onMouseDown={this.onStartDrag(id)}/>
          </td>
        </tr>
        {isDropUnder ? <tr><td colSpan={4} style={{height: '41px'}}></td></tr> : null}
      </Fragment>
    )
  }

  getRows = (schedule) => {
    let tomatoIndex = 0
  
    return schedule.map((item, idx) => {
      if (item.isTomato) {
        tomatoIndex++
      }
      const row = {
        ...item,
        tomatoIndex: item.isTomato ? tomatoIndex : ''
      }
      //return this.getRow(row)
      // const rowProps = {
      //   ...row,
      //   onDone: this.onDone,
      //   onChangeTomato: this.onChangeTomato,
      //   onInputTomato: this.onInputTomato,
      //   onStartTimeInput: this.onStartTimeInput,
      //   onInputTitle: this.onInputTitle,
      //   onTimerChange: this.onTimerChange,
      //   onStartTimer: this.onStartTimer,
      //   onStopTimer: this.onStopTimer,
      //   onDoneTimer: this.onDoneTimer
      // }
      return this.getRow(row, idx)
    })
  }

  render () {
    const {schedule} = this.state
  
    return (
      <div className="plan">

        <div className="app-content__title">
          <h1>{getDateStr(this.props.schedule.createdAt)}</h1>
          {/*<Button>Дублировать</Button>*/}
        </div>
        <DndContext>
        <table className="plan__table">
          <tbody>
              {this.getRows(calculateSchedule(schedule))}
          </tbody>
        </table>
        </DndContext>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePlan: payload => dispatch(updatePlan(payload))
  }
}

export default connect((state)=>state, mapDispatchToProps)(Plan)