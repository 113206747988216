import Button from "../../ui/button"
import PlansList from "../../plans-list"
import { connect } from "react-redux"
import { addPlan } from "../../../actions"
import { getDefaultSchedule, z } from "../../../utils"

import "./list-page.css"

const genPlan = () => {
  const now = new Date()
  const date = `${now.getFullYear()}-${z(now.getMonth()+1)}-${z(now.getDate())}`
  const createdAt = (new Date(date)).getTime()
  const id = createdAt.toString(16)

  return {
    id,
    createdAt,
    plan: getDefaultSchedule()
  }
}

const ListPage = ({addPlan}) => {
  return (
    <div className="app-content list-page">
      <div className="app-content__title">
        <h1>My plans</h1>
        <Button onClick={() => addPlan(genPlan())}>Add new</Button>
      </div>
      <PlansList />
    </div>
  )
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    addPlan: (plan) => dispatch(addPlan(plan))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPage)